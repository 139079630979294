@import url("https://rsms.me/inter/inter-ui.css");

@import './styles/constants.scss';
@import '~bootstrap/scss/bootstrap.scss';

body {
  margin: 0;
  padding: 0;

  font-family: InterUI, "Helvetica Neue", sans-serif;
  text-align: center;

  background-color: $primary;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.color-primary       { color: $primary }
.color-light         { color: $light }
.color-light-variant { color: $light-variant }
.color-medium-light  { color: $medium-light }
.color-medium-dark   { color: $medium-dark }
.color-dark          { color: $dark }

.content-wrapper {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;

  font-size: calc(10px + 2vmin);
  color: $medium-light;
}

.link:link, .link:visited {
  color: $light-variant;
}

.link:hover, .link:active {
  color: $light;
}

.logo {
  font-weight: bolder;
  font-size: calc(40px + 2vmin);
}
